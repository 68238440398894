
import Vue from 'vue';
import Component from 'vue-class-component'

import AudioUpload from '@/components/dialogs/AudioUpload.vue';
import SearchCard from '@/components/cards/SearchCard.vue';
import NotFoundCard from '@/components/cards/NotFoundCard.vue';


import { Audio } from '@/entities/audio/Audio';
import AudioCard from '@/components/cards/AudioCard.vue';

@Component({
  components: {
    AudioUpload,
    AudioCard,
    SearchCard,
    NotFoundCard
  }
})
export default class MusicList extends Vue{
  audios: Audio[] = [];

  search = "";
  dialog = false;

  mounted() {
    this.fetchAudios();
  }

  get filtered() {
    return this.$helpers.filterAudios(this.audios, this.search);
  }

  async fetchAudios() {
    this.$audios.list().then(audios => {
      this.audios = audios;
    });
  }
}
